import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Header from "../Components/Header";
import Footer from "./Footer";
const Terms = () => {
  return (
    <>
 <Header />
      {/* <!--
End Fixed Navigation
==================================== --> */}

<section className="terms-page">
    <div className="container">
        <div className="row">
            <div className="col-md-12">
            <h2>Terms of Service</h2>
            <p>Welcome to Netsparked Infotech. By accessing or using our website, products, or services, you agree to comply with and be bound by the following terms and conditions. Please read them carefully before engaging with our services.</p>
            </div>

            <div className="col-md-12 mt-3">
                <h4>1. Acceptance of Terms</h4>
                <p>By using our services, you agree to these Terms of Service. If you do not agree with any part of these terms, you must not use our services.</p>
            </div>

            <div className="col-md-12 mt-3">
                <h4>2. Definitions</h4>
                <p>

                    <ul>
                   <li>Services refers to any services, products, or solutions provided by Netsparked Infotech.</li>
                   <li>Client refers to the person or entity utilizing Netsparked Infotech services.</li>
                   <li>Agreement refers to the terms and conditions outlined in this document.</li>
                  
</ul>
</p>
            </div>

            <div className="col-md-12 mt-3">
                <h4>Services Provided</h4>
                <p>Netsparked Infotech specializes in the following:</p>
                <ul>
                    <li>Software Development
                    </li>
                    <li>Web and Mobile Application Development
                    </li>
                    <li>E-Commerce Solutions
                    </li>
                    <li>Digital Marketing
                    </li>
                    <li>IT Consulting and Support
                    </li>
                    <li>Services are provided as per the scope of the project agreed upon with the client.
                    </li>
                </ul>
            </div>


            <div className="col-md-12 mt-3">
                    <h4>4. User Responsibilities
                    </h4>
                    <p>As a client, you agree to:
                    </p>
                    <ul>
                        <li>Provide accurate and complete information as needed for our services.</li>
                        <li>Use our services only for lawful purposes and in compliance with applicable laws and regulations.
                        </li>
                        <li>Respect our intellectual property rights and the terms outlined in this agreement.
                        </li>
                    </ul>
            </div>

            <div className="col-md-12 mt-3">
                <h4>5. Payment Terms
                </h4>
                <ul>
                    <li>Payment must be made in accordance with the invoices or agreements provided.</li>
                    <li>Delayed payments may result in suspension or termination of services.
                    </li>
                    <li>Any applicable taxes, fees, or additional costs will be borne by the client unless otherwise specified.
                    </li>
                </ul>
            </div>

            <div className="col-md-12 mt-3">
                <h4>6. Intellectual Property
                </h4>
                <p>All intellectual property developed or provided by Netsparked Infotech remains the exclusive property of the company unless explicitly transferred by written agreement.
                The client is granted a limited license to use the deliverables for their intended purpose.</p>
            </div>

            <div className="col-md-12 mt-3">
                <h4>7. Confidentiality
                </h4>
                <p>Both parties agree to keep confidential any proprietary or sensitive information shared during the course of service delivery.
                </p>
            </div>

            <div className="col-md-12 mt-3">
                <h4>8. Limitation of Liability
                </h4>
                <p>Netsparked Infotech shall not be liable for any indirect, incidental, or consequential damages arising from the use of our services. Total liability is limited to the fees paid for the specific service.</p>
            </div>


            <div className="col-md-12 mt-3">
                <h4>9. Termination
                </h4>

                <ul>
                    <li>Either party may terminate the agreement by providing written notice.
                    </li>
                    <li>Upon termination, all outstanding payments must be settled, and access to services will be revoked.
                    </li>
                </ul>
            </div>


            <div className="col-md-12 mt-3">
              <h4>10. Dispute Resolution
              </h4>
              <p>Any disputes arising under these terms will be resolved through mutual discussions. Failing resolution, disputes will be handled under the jurisdiction of [Insert Legal Location/Country].
              </p>
            </div>


            <div className="col-md-12 mt-3">
                <h4>11. Updates to Terms
                </h4>
                <p>Netsparked Infotech reserves the right to update or modify these terms at any time. Clients will be notified of significant changes via email or our website.
                </p>
            </div>

            <div className="col-md-12 mt-3">
                <h4>12. Contact Us
                </h4>
                <p>If you have questions about these Terms of Service, please contact us:
                </p>
                  <ul>
                    <li>Netsparked Infotech
                    </li>
                    <li>Email: info@netsparked.com
                    </li>
                    <li>Phone: +91-172-4606237
                    </li>
                    <li>Address: F-237 Phase 8-B, Industrial Area, Sector 74,Sahibzada Ajit Singh Nagar
                    </li>
                    <li>Punjab IN 160070
                    </li>
                  </ul>
            </div>
        </div>
    </div>
 
</section>




<Footer />
    </>
  );
};

export default Terms;