import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Header from "../Components/Header";
import Footer from "./Footer";
const  Privacy = () => {
  return (
    <>
 <Header />
      {/* <!--
End Fixed Navigation
==================================== --> */}

<section className="terms-page">
    <div className="container">
        <div className="row">
            <div className="col-md-12">
            <h2>Privacy Policy</h2>
            <p>Netsparked Infotech is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, store, and protect your information when you use our services or interact with our website.</p>
            </div>

            <div className="col-md-12 mt-3">
                <h4>Information We Collect</h4>
                <p>We may collect the following types of information:
                </p>
                <h5>(a) Personal Information</h5>
                <p>Name, email address, phone number, and billing information provided when you contact us or use our services.
                Details submitted through forms, registrations, or inquiries on our website.</p>
                <h5>(b) Non-Personal Information
                </h5>
                <p>IP address, browser type, and device information collected automatically to improve our website's functionality.
                Data about your usage patterns on our website, such as pages visited and time spent.</p>
            </div>

            <div className="col-md-12 mt-3">
                <h4>2. How We Use Your Information
                </h4>
                <p>We use the information we collect for the following purposes:</p>
                <p>

                    <ul>
                   <li>To provide, manage, and improve our services.</li>
                   <li>To process payments, orders, or requests.
                   </li>
                   <li>To send important updates, promotional offers, and other marketing communications.
                   </li>
                   <li>To enhance your user experience on our website.
                   </li>
                   <li>To comply with legal and regulatory obligations.
                   </li>
                  
</ul>
</p>
            </div>

            <div className="col-md-12 mt-3">
                <h4>4. Data Storage and Security
                </h4>
               
                <ul>
                    <li>We store your data securely using industry-standard encryption technologies.

                    </li>
                    <li>Access to your data is restricted to authorized personnel only.

                    </li>
                    <li>Despite our best efforts, no method of transmission over the Internet or electronic storage is completely secure.

                    </li>
                  
                </ul>
            </div>


            <div className="col-md-12 mt-3">
                    <h4>5. Cookies and Tracking Technologies

                    </h4>
                    <p>We use cookies and similar technologies to enhance user experience and analyze site performance. Cookies are small text files stored on your device that help us recognize your preferences.

                    </p>
                    <p>Managing Cookies:
                    </p>
                    <p>You can control cookies through your browser settings. However, disabling cookies may impact website functionality.
                    </p>
                  
            </div>

            <div className="col-md-12 mt-3">
                <h4>6. Third-Party Links

                </h4>
                <p>Our website may contain links to external websites. We are not responsible for their privacy practices. Please review their privacy policies before sharing personal data.
</p>
               
            </div>

            <div className="col-md-12 mt-3">
                <h4>7. Children's Privacy
                </h4>
                <p>Our services are not directed at children under the age of 13. We do not knowingly collect personal information from children.
                </p>
            </div>

            <div className="col-md-12 mt-3">
                <h4>8. Your Rights
                </h4>
                <p>As a user, you have the right to:

                </p>
                <ul>
                    <li>Access, update, or delete your personal data.
                    </li>
                    <li>Opt-out of marketing communications by contacting us.
                    </li>
                    <li>Request details about how we process your data.
                    </li>
                </ul>
            </div>

            <div className="col-md-12 mt-3">
                <h4>9. Updates to This Privacy Policy

                </h4>
                <p>We may update this Privacy Policy periodically. Changes will be posted on this page with the updated date.
                </p>
            </div>


           


    

            <div className="col-md-12 mt-3">
                <h4>10. Contact Us
                </h4>
                <p>If you have any questions or concerns about this Privacy Policy or how we handle your data, please contact us:

                </p>
                  <ul>
                    <li>Netsparked Infotech
                    </li>
                    <li>Email: info@netsparked.com
                    </li>
                    <li>Phone: +91-172-4606237
                    </li>
                    <li>Address: F-237 Phase 8-B, Industrial Area, Sector 74,Sahibzada Ajit Singh Nagar
                    </li>
                    <li>Punjab IN 160070
                    </li>
                  </ul>
            </div>
        </div>
    </div>
 
</section>




<Footer />
    </>
  );
};

export default Privacy;