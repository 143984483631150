import React from "react";
import { Link } from "react-router-dom";
import "../assets/award/style.css"; // Ensure path accuracy
import Header from "../Components/Header";
import Footer from "./Footer";
import twinkle from "../assets/img/team/twinkle.jpg";
import anoop from "../assets/img/team/anoop.jpg";
import rajiv from "../assets/img/team/rajiv.jpg";
import manoj from "../assets/img/team/manoj.jpg";
import manpreet from "../assets/img/team/manpreet.jpg";
import suresh from "../assets/img/team/suresh.jpg";
import ajay from "../assets/img/team/ajay.jpg";
import piyush from "../assets/img/team/piyush.jpg";
import rohit from "../assets/img/team/rohit.jpg";
import sailesh from "../assets/img/team/team-2.webp";
import sanjeev from "../assets/img/team/md.jpg";

const OurTeam = () => {
  return (
    <>
      <Header />
      <section className="single-page-header">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>Our Team</h2>
              <ol className="breadcrumb header-breadcrumb justify-content-center">
                <li className="breadcrumb-item">
                  <Link to="/" className="text-white">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Our Team
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>


      <div className="py-5 team4">
        <div className="container">
          <div className="row justify-content-center mb-4 team-member-container">
            <div className="col-md-12 text-center">
              <h3 className="mb-3">Experienced & Professional Team</h3>
            </div>
            <div class="col-md-4 text-center mt-5 mb-5 top-main md-img">
                  <img src={sanjeev}  />
                  <div class="pt-2 mt-4"><h5 class=" font-weight-medium mb-0">Sanjeev Kumar</h5><h6 class="subtitle mb-3">Managing Director MD</h6><p></p></div>
                </div>
          </div>
          <div className="row">
          
            {/** Team Members Section **/}
            {[
              {
                name: "Manoj Kumar",
                title: "Project Manager/Team Leader PM",
                image:
                  manoj,
                description:
                  "",
                urls:
                  "https://netsparked.com/our-team/manoj-kumar",
              },
              {
                name: "Anoop Kumar",
                title: "Team Lead/Senior Developer-TL",
                image:
                anoop,
                description:
                  "",
                urls:
                  "https://netsparked.com/our-team/Anoop-kumar",
              },
              {
                name: "Rohit Kumar",
                title: "Senior Server Administrator SSA",
                image:
                  rohit,
                description:
                  "",
                urls:
                  "https://netsparked.com/our-team/Ajay-kumar",
              },
              {
                name: "Ajay Kumar",
                title: "Senior Software Developer",
                image:
                  ajay,
                description:
                  "",
                urls:
                  "https://netsparked.com/our-team/Ajay-kumar",
              },
              {
                name: "Rajiv Kumar",
                title: "Full Stack Developer FSD",
                image:
                rajiv,
                description:
                  "",
                urls:
                  "https://netsparked.com/our-team/Rajiv-kumar",
              },
              {
                name: "Twinkle",
                title: "Business Development Executive BDE",
                image:
                twinkle,
                description:
                  "",
                urls:
                  "https://netsparked.com/our-team/twinkle-rajput",
              },
              {
                name: "Manpreet Kaur",
                title: "Web Designer",
                image:
                  manpreet,
                description:
                  "",
                urls:
                  "https://netsparked.com/our-team/manpreet-kaur",
              },
              {
                name: "Piyush",
                title: "Software Developer",
                image:
                piyush,
                description:
                  "",
                urls:
                  "https://netsparked.com/our-team/piyush",
              },
              {
                name: "Suresh Kumar",
                title: "Front End Developer",
                image:
                 suresh,
                description:
                  "",
                urls:
                  "https://netsparked.com/our-team/suresh-kumar",
              },
              {
                name: "Sailesh Raturi",
                title: "Buisness Development Executive BDE",
                image:
                sailesh,
                description:
                  "",
                urls:
                  "https://netsparked.com/our-team/suresh-kumar",
              },
            ].map((teamMember, index) => (
              <div
                className={`col-md-6 col-lg-3 mb-4`}
                key={index}
              >
                <div className="top-main mt-4">
                
                    <div className="col-md-12 team-img">
                      <img
                        src={teamMember.image}
                        alt={`${teamMember.name}`}
                        className="img-fluid rounded-circle"
                      />
                    </div>
                    <div className="col-md-12 text-center team-member">
                      <div className="pt-2">
                        <h5 className=" font-weight-medium mb-0">
                          {teamMember.name}
                        </h5>
                        <h6 className="subtitle mb-3">{teamMember.title}</h6>
                        <p>{teamMember.description}</p>
                      </div>
                    </div>
                  
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>


      <Footer />
    </>
  );
};

export default OurTeam;
